import React from "react"
import { graphql } from "gatsby"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Menu from '../components/menu'
import Footer from '../components/footer'
import Seo from '../components/seo'
import FeaturedImage from '../components/featured-image'
import FormattedText from '../components/formatted-text'

const Page = ({ data }) => {
    
    const post = data.strapiPages
    const seo = {
      metaTitle: post.title,
      metaDescription: "",
      shareImage: post.image,
      article: true,
    }
    return (
    <>
    <Seo seo ={seo}/>
    <Container fluid="md">
        <Row>
        <Col >
            <Menu/>
        </Col>
        </Row>
        <Row id ="content-row">
        <Col>
        <Row>
        <FeaturedImage alt={post.title || ""} image={post.image || ""} post={true} />
        </Row>
        <FormattedText title= {post.title || ""} content={post.content || ""} allowHTML={true} />
        </Col>
        </Row>
        <Footer/>
    </Container>
    </>

            
    )
}

/*

 */

export const query = graphql`
  query MyPagesQuery($slug: String!){
    strapiPages (slug: {eq: $slug}){
        title
        slug
        content
        image {
            localFile {
                publicURL
                childImageSharp {
                    gatsbyImageData(height: 300)
                }
            }
        }
    }
  }
`

export default Page