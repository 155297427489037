import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const FeaturedImage = ({alt, image, post}) => {
    //console.log(image.localFile.childImageSharp.gatsbyImageData)
    const displayedImage = getImage(image.localFile)
    try{
        return(
            <div class="image-div">
            {
                (post ?
                    <GatsbyImage
                    style={{
                        "position":"absolute",
                        "display": "block",
                        "-webkit-filter": "opacity(40%)",
                        "-moz-filter": "opacity(40%)",
                        "-o-filter": "opacity(40%)",
                        "-ms-filter": "opacity(40%)",
                        "filter": "opacity(40%)",
                        "width":"100%",
                        
                    }}
                    alt={`blurred-${alt}`}
                    image={displayedImage}
                    layout="constrained"
    
                    />:
                    <></>
                 )
            }
            {
                (post ?
                    <GatsbyImage
                    style={{
                        "position":"relative",
                        "margin-left": "auto",
                        "margin-right": "auto",
                        "vertical-align":"middle",
                        "display":"table",
                    }}
                    alt={alt}
                    image={displayedImage}
                    layout="constrained"

                    />
    
                    :
                    <GatsbyImage
                    style={{
                        "position":"relative",
                        "margin-left": "auto",
                        "margin-right": "auto",
                        "vertical-align":"middle",
                    }}
                    alt={alt}
                    image={displayedImage}
                    layout="constrained"

                    />
                 )
            }  
                    

                    

                
            </div>
        )
    } catch{
        //console.log(image)
        return (<></>)
    }
    
}

export default FeaturedImage
